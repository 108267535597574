<h1 mat-dialog-title>Acesso negado</h1>
<mat-dialog-content>
  <p>Você não tem permissão para acessar essa parte da aplicação</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button
          mat-dialog-close
          color="primary"
          cdkFocusInitial>
    Ok
  </button>
</mat-dialog-actions>